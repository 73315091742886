import React from 'react';
import { Table } from 'semantic-ui-react';

import NoData from '../Tables/NoData';

const TableTimeColumns = {
  'dateinsert': 'Время создания',
  'pointofcontrol': 'Точка учета',
  'conclusion': 'Заключение',
  'description_status': 'Статус',
  'description_error': 'Ошибка',
  'conclusionDate': 'Время ответа',
  'replyid': 'Транспортный код'
};

const TableAsiiuTimeTasks = ({ data, flag }) => {
  const isAllDataReady = data && data.length > 0;
  return <div>
    {!isAllDataReady && <NoData />}
    {isAllDataReady && <div>
      <Table collapsing compact celled textAlign='center'>
        <Table.Header>
          <Table.Row>
            { Object.keys(data[0]).map((k, i) => { return <Table.HeaderCell key={i}>{TableTimeColumns[k]}</Table.HeaderCell>; }) }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data.map((row, i) => {
                return <Table.Row key={i}>
                  <Table.Cell key={1}>{row['dateinsert'].replace('T', ' ').replace('Z', '')}</Table.Cell>
                  {flag === 'asiiutime' ? <Table.Cell key={2}>{row['pointofcontrol']}</Table.Cell> : null}
                  <Table.Cell key={3}>{row['conclusion'].Valid ? (row['conclusion'].String === 'Rejected' ? 'Отказано' : 'Accepted' ? 'Принято' : '') : ''}</Table.Cell>
                  <Table.Cell key={4}>{row['description_status'].Valid ? row['description_status'].String : ''}</Table.Cell>
                  <Table.Cell key={5}>{row['description_error'].Valid ? row['description_error'].String : ''}</Table.Cell>
                  <Table.Cell key={6}>{row['conclusionDate'].Valid ? row['conclusionDate'].String.replace('T', ' ').substring(0, row['conclusionDate'].String.indexOf('.')) : ''}</Table.Cell>
                  <Table.Cell key={7}>{row['replyid'].Valid ? row['replyid'].String : ''}</Table.Cell>
                </Table.Row>
              }
            )
          }
        </Table.Body>
      </Table>
    </div>}
  </div>;
};

export default TableAsiiuTimeTasks;